import { IUserHealth } from '../../legacy/types/healthCheck';
import { HealthActions, HealthActionTypes } from '../actions/healthActions';

const defaultState: IUserHealth = {
    availableTest: null,
    score: [],
};

export const healthReducer = (
    state: IUserHealth = defaultState,
    action: HealthActions
): IUserHealth => {
    switch (action.type) {
        case HealthActionTypes.FETCH_USER_HEALTH:
            return {
                ...state,
                availableTest: action.payload.availableTest,
                score: [...action.payload.score],
            };
        default:
            return state;
    }
};
