import { userReducer } from './userReducer';
import { combineReducers } from 'redux';
import { settingsReducer } from './settingsReducer';
import { healthReducer } from './healthReducer';
import { customerReducer } from './customerReducer';
import { notificationReducer } from './notificationReducer';
import { paymentsReducer } from './paymentReducer';
import { quizReducer } from './quizReducer';
import { stripeReducer } from './stripeReducer';
import { eligibilityReducer } from './eligibilityReducer';

export const rootReducer = combineReducers({
    user: userReducer,
    settings: settingsReducer,
    health: healthReducer,
    customer: customerReducer,
    notifications: notificationReducer,
    payments: paymentsReducer,
    quiz: quizReducer,
    stripe: stripeReducer,
    eligibility: eligibilityReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
