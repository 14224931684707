import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers';

const initStore = () => {
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    // @ts-ignore
    const composeEnhancers = compose;
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default initStore;
