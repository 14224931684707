import {IUser} from "../../legacy/types/user";
import {UserActions, UserActionTypes} from "../actions/userActions";

export const defaultState: IUser = {
    isLoading: true,
    id: '',
    email: '',
    password: '',
    phone: '',
    firstName: '',
    middleName: null,
    lastName: null,
    currentAddress: {
        address1: '',
        address2: '',
        address3: '',
        town: '',
        county: '',
        postCode: '',
        dateMovedIn: ''
    },
    dob: '',
    overThreeYears: false,
    previousAddress: {
        address1: '',
        address2: '',
        address3: '',
        town: '',
        county: '',
        postCode: '',
    },
    isVerified: false,
    fromBroker: false,
    externalRef: '',
};

export const userReducer = (state: IUser = defaultState, action: UserActions): IUser => {
    switch (action.type) {
        case UserActionTypes.SET_EMAIL_PASSWORD:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
            };
        case UserActionTypes.SET_FULL_NAME:
            return {
                ...state,
                firstName: action.payload.firstName,
                middleName: action.payload.middleName,
                surname: action.payload.surname,
            };
        case UserActionTypes.SET_AGE:
            return {
                ...state,
                dob: action.payload.dob,
            };
        case UserActionTypes.SET_MOBILE_PHONE:
            return {
                ...state,
                phone: action.payload.phone,
            };
        case UserActionTypes.SET_ADDRESS:
            return {
                ...state,
                currentAddress: {
                    ...state.currentAddress,
                    ...action.payload.currentAddress
                },
            };
        case UserActionTypes.SET_DATE_MOVED_IN:
            return {
                ...state,
                currentAddress: {
                    ...state.currentAddress,
                    dateMovedIn: action.payload.dateMovedIn
                }
            }
        case UserActionTypes.SET_TIME_SPENT:
            return {
                ...state,
                overThreeYears: action.payload.overThreeYears,
            };
        case UserActionTypes.SET_PREVIOUS_ADDRESS:
            return {
                ...state,
                previousAddress: action.payload.previousAddress,
            };
        case UserActionTypes.SET_FETCHED_USER:
            return {
                ...action.payload.user
            }
        case UserActionTypes.SET_EXTERNAL_REF:
            return {
                ...state,
                externalRef: action.payload.externalRef
            }
        case UserActionTypes.DELETE_USER:
            return {
                ...defaultState
            }
        case UserActionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload.email
            }
        case UserActionTypes.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.value
            }
        default:
            return state;
    }
};
