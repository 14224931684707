import { ICustomer } from '../../legacy/types/user';
import {
    CustomerActions,
    CustomerActionTypes,
} from '../actions/customerActions';

type ICustomerState = Omit<ICustomer, 'jwt' | 'user'>;

export const defaultState: ICustomerState = {
    date: '',
    creditScoreHistory: [],
    credit: null,
    frauds: null,
    affordability: null,

};

export const customerReducer = (
    state: ICustomerState = defaultState,
    action: CustomerActions
): ICustomerState => {
    switch (action.type) {
        case CustomerActionTypes.SET_CUSTOMER:
            return {
                ...state,
                date: action.payload.date,
                creditScoreHistory: action.payload.creditScoreHistory,
                affordability: action.payload.affordability,
                credit: action.payload.credit,
                frauds: action.payload.frauds,
            };
        case CustomerActionTypes.DELETE_CUSTOMER:
            return {
                ...defaultState,
            };
        default:
            return state;
    }
};
