exports.components = {
  "component---src-components-brochurware-about-us-tsx": () => import("./../../../src/components/brochurware/about-us.tsx" /* webpackChunkName: "component---src-components-brochurware-about-us-tsx" */),
  "component---src-components-brochurware-cookies-tsx": () => import("./../../../src/components/brochurware/cookies.tsx" /* webpackChunkName: "component---src-components-brochurware-cookies-tsx" */),
  "component---src-components-brochurware-privacy-tsx": () => import("./../../../src/components/brochurware/privacy.tsx" /* webpackChunkName: "component---src-components-brochurware-privacy-tsx" */),
  "component---src-components-brochurware-product-terms-tsx": () => import("./../../../src/components/brochurware/product-terms.tsx" /* webpackChunkName: "component---src-components-brochurware-product-terms-tsx" */),
  "component---src-components-home-page-index-tsx": () => import("./../../../src/components/HomePage/index.tsx" /* webpackChunkName: "component---src-components-home-page-index-tsx" */),
  "component---src-legacy-templates-faq-page-tsx": () => import("./../../../src/legacy/templates/FAQPage.tsx" /* webpackChunkName: "component---src-legacy-templates-faq-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */)
}

