export interface Price {
    id: string,
    unit_amount: number
}
export const enum StripeActionTypes {
    SET_FETCHED_PRICES = 'SET_FETCHED_PRICES',
    SET_PRICE = 'SET_PRICE',
    SET_CLIENT_SECRET = 'SET_CLIENT_SECRET'
}

export interface setFetchedPricesAction {
    type: StripeActionTypes.SET_FETCHED_PRICES;
    payload: {
        prices: Price[]
    }
}

export interface setPrice {
    type: StripeActionTypes.SET_PRICE;
    payload: {
        price: Price
    }
}

export interface setClientSecret {
    type: StripeActionTypes.SET_CLIENT_SECRET;
    payload: {
        clientSecret: string
    }
}

export type StripeActions = setFetchedPricesAction | setPrice | setClientSecret;