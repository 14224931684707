import { IUserHealth } from '../../legacy/types/healthCheck';

export const enum HealthActionTypes {
    FETCH_USER_HEALTH = 'FETCH_USER_HEALTH',
}

export interface fetchUserHealth {
    type: HealthActionTypes.FETCH_USER_HEALTH;
    payload: IUserHealth;
}

export type HealthActions = fetchUserHealth;
