import axios, { AxiosResponse } from 'axios';
import { IUser } from '../types/user';
import { IUserDataFromBroker, IUserResponse } from '../types/userResponse';
import { IUserHealth } from '../types/healthCheck';
import { VerifyResponse } from '../types/responce';
import { Buffer } from 'buffer';

const apiUrl: string = process.env.PROD
    ? process.env.PROD_AUTH_SERVICE_URL
    : process.env.AUTH_SERVICE_URL;

const api = axios.create({
    baseURL: apiUrl,
});

export class UserService {
    static async signIn(
        email,
        password
    ): Promise<AxiosResponse<VerifyResponse>> {
        return await api.post<VerifyResponse>('/signin', {
            email,
            password,
        });
    }

    static async signUp(user: IUser) {
        let res
        try{
            res = await api.post('/signup', {
                ...user,
            });
            console.log(res)
            return res
        } catch (e) {
            console.log('ERROR FROM AXIOS', JSON.parse(JSON.stringify(e.response)))
            throw new Error(JSON.stringify({errorCode: e.response.data.errorCode}))
        }

    }

    static async verifyUser(
        externalRef: string,
        userId?: string
    ): Promise<AxiosResponse<VerifyResponse>> {
        let res
        try{
            res = await api.post<VerifyResponse>('/verifyUser', {
                externalRef,
                userId
            });
            console.log(res)
            return res
        } catch (e) {
            console.log('ERROR FROM AXIOS', JSON.parse(JSON.stringify(e.response)))
            throw new Error(JSON.stringify({errorCode: e.response.data.errorCode}))
        }
    }

    static async getUser(jwt): Promise<AxiosResponse<VerifyResponse>> {
        const user = await api.get<VerifyResponse>('/me', {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
        return user;
    }

    static async getResetLink(email) {
        try {
            const res = await api.post('/getResetLink', { email });
            return res;
        } catch (e) {
            console.log(e);
        }
    }

    static async resetPassword(
        token,
        newPassword
    ): Promise<AxiosResponse<IUserResponse>> {
        try {
            const user = await api.post<IUserResponse>('/reset-password', {
                token,
                newPassword,
            });
            return user;
        } catch (e) {
            console.log(e);
        }
    }

    static async getAvailableHealthCheck(
        userId
    ): Promise<AxiosResponse<IUserHealth>> {
        try {
            return await api.post<IUserHealth>('/getUserHealthScore', {
                userId,
            });
        } catch (e) {
            console.log(e);
        }
    }

    static async sendPassedHealthTest(
        testId,
        score,
        jwt
    ): Promise<AxiosResponse<IUserHealth>> {
        try {
            return await api.post<IUserHealth>(
                '/createUserHealth',
                {
                    testId,
                    score,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    }

    static async cancelAccount(jwt, reason) {
        try {
            return await api.post(
                '/cancelAccount',
                { reason },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    }

    static async updateEmail(jwt, password, email) {
        return await api.post(
            '/updateEmail',
            {
                email,
                password,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        );
    }

    static async updatePassword(jwt, password, newPassword) {
        return await api.post(
            '/updatePassword',
            {
                password,
                newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        );
    }

    static async fetchStripeClientSecret(email) {
        try {
            return await api.post('/getStripeClient', {
                email,
            });
        } catch (e) {
            console.log(e);
        }
    }

    static async fetchPaymentData(userId) {
        return await api.post('/upgradeCustomerSubscription');
    }

    static async fetchUserDataFromBroker(
        userId
    ): Promise<AxiosResponse<IUserDataFromBroker>> {
        try {
            const token = Buffer.from(
                `${process.env.RAPORTO_SITE_KEY}:${process.env.RAPORTO_SITE_PASSWORD}`,
                'utf8'
            ).toString('base64');

            // const token = authPayload.data.res;
            return await api.get<IUserDataFromBroker>(
                `/broker/user/${userId}`,
                {
                    headers: {
                        Authorization: `Basic ${token}`,
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    }

    static async fetchStripeClientForBroker(email) {
        try {
            return await api.post('/broker/stripe', {
                email,
            });
        } catch (e) {
            console.log(e);
        }
    }

    static async updateUserData(
        userId,
        userData
    ): Promise<AxiosResponse<IUserDataFromBroker>> {
        return await api.put<IUserDataFromBroker>('/broker/user', {
            userId,
            userData,
        });
    }

    static async setDefaultPaymentMethod(userId) {
        return await api.post('/user/payment', {
            userId,
        });
    }

    public static getUserDataFromBrokerV3 = async (userId) => {
        const token = Buffer.from(
            `${process.env.RAPORTO_SITE_KEY}:${process.env.RAPORTO_SITE_PASSWORD}`,
            'utf8'
        ).toString('base64');
        return await api.get(`/v3/user/${userId}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
    };
    public static getInvoice = async (userId, priceId) => {
        return await api.post('/v3/invoices', {
            userId,
            priceId,
        });
    };
    public static updateUserV3 = async (userId, userData, answers) => {
        return await api.put('/v3/user', {
            userId,
            userData,
            answers,
        });
    };

    public static getUserLoans = async (id) => {
        return await api.post('/user/loans', {
            id,
        });
    };

    public static createStripeAccount = async (id: string) => {
        return await api.post('stripe/customer/client', {
            id,
        });
    };

    public static updateUserStage = async (userId, stage, status) => {
        return await api.put('/stage', {
            userId,
            stage,
            status,
        });
    };
}
