import { QuizActionTypes, QuizActions } from '../actions/quizActions';
import { IQuestion } from '../../v1/components/Quiz/static/stagesData';

type StateType = {
  answers: { [ key: string ]: string | null}
  questions: IQuestion[]
}

const defaultState = {
  questions: [],
  answers: {
    describeYourself: null,
    approachToFinances: null,
    financialKnowledgeLevel: null,
    financialDecisionMaking: null,
    managingFinancesImportance: null,
    financialGoals: null,
    parentsFinancialSituation: null,
    firstBankAccountAge: null,
    creditNeeds: null,
    creditBlockers: null,
    importanceOfUnderstandingCreditScore: null,
    creditScoreCheckingFrequency: null,
    financesTrackingMethod: null,
    budgetFamiliarity: null,
    bestFinanceManagementAdvice: null,
    futureFinanceManagementConfidence: null,
    financialSecurityMotivation: null,
    biggestFinancialConcerns: null,
    creditRefusalEmotions: null,
    pastFinanceDecisionSatisfaction: null,
  },
};

export const quizReducer = (state: StateType = defaultState, action: QuizActions) => {
  switch (action.type) {
    case QuizActionTypes.ADD_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [ action.payload.name ]: action.payload.answer,
        },
      };
    case QuizActionTypes.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
      };
    default:
      return state;
  }
};