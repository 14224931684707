import * as React from 'react';
import { useEffect } from 'react';
import {useActions} from "../../hooks/useActions";

export const LoadWrapper = ({ children }) => {
    const {fetchRememberedUser} = useActions();


    useEffect(() => {
        fetchRememberedUser()
    }, []);

    return <>{children}</>;
};
