export class NotificationService {
    private wsConnection: WebSocket;

    constructor(wsObj) {
        this.wsConnection = wsObj;
    }

    public checkMessage = (messageId) => {
        try {
            this.wsConnection.send(JSON.stringify({action: 'checkMessage', body: {messageId}}));
        } catch (e) {
            console.log(e)
        }
    }

    public getDelayedMessages = async() => {
        try {
            this.wsConnection.send(JSON.stringify({ action: 'sendDelayedMessages' }))
        } catch (e) {
            console.log(e)
        }
    }
}