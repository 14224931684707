import { Address } from '../reducers/eligibilityReducer';
import { SelectOption } from '../../types/select';

export const enum EligibilityActionTypes {
    SET_ID = 'SET_ID',
    SET_EMAIL = 'SET_EMAIL',
    SET_LOAN_AMOUNT = 'SET_LOAN_AMOUNT',
    SET_REPAYMENT_AMOUNT = 'SET_REPAYMENT_AMOUNT',
    SET_LOAN_TERM = 'SET_LOAN_TERM',
    SET_LOAN_PURPOSE = 'SET_LOAN_PURPOSE',
    SET_TITLE = 'SET_TITLE',
    SET_FIRST_NAME = 'SET_FIRST_NAME',
    SET_LAST_NAME = 'SET_LAST_NAME',
    SET_DOB = 'SET_DOB',
    SET_PHONE = 'SET_PHONE',
    SET_ADDRESS = 'SET_ADDRESS',
    SET_RESIDENTIAL_INFO = 'SET_RESIDENTIAL_INFO',
    SET_RESIDENTIAL_VALUE = 'SET_RESIDENTIAL_VALUE',
    SET_PREVIOUS_ADDRESS = 'SET_PREVIOUS_ADDRESS',
    SET_INCOME = 'SET_INCOME',
    SET_EMPLOYMENT = 'SET_EMPLOYMENT',
    SET_EXPENDITURE = 'SET_EXPENDITURE',
    SET_CREDIT = 'SET_CREDIT',
    SET_MARITAL_STATUS = 'SET_MARITAL_STATUS',
    SET_BANK_DETAILS = 'SET_BANK_DETAILS',
    SET_IP_ADDRESS_USER_AGENT = 'SET_IP_ADDRESS_USER_AGENT',
    SET_EXTERNAL_REF = 'SET_EXTERNAL_REF',
    SET_PAYMENT_FREQUENCY = 'SET_PAYMENT_FREQUENCY',
    SET_SOURCE = 'SET_SOURCE',
}

export interface setId {
    type: EligibilityActionTypes.SET_ID;
    payload: {
        id: string;
    };
}
export interface setLoanAmount {
    type: EligibilityActionTypes.SET_LOAN_AMOUNT;
    payload: {
        loanAmount: number;
    };
}

interface setRepaymentAmount {
    type: EligibilityActionTypes.SET_REPAYMENT_AMOUNT;
    payload: {
        repaymentAmount: number;
    };
}

interface setEmail {
    type: EligibilityActionTypes.SET_EMAIL;
    payload: {
        email: string;
    };
}
interface setLoanTerm {
    type: EligibilityActionTypes.SET_LOAN_TERM;
    payload: {
        loanTerm: number;
    };
}
interface setLoanPurpose {
    type: EligibilityActionTypes.SET_LOAN_PURPOSE;
    payload: {
        loanPurpose: string;
    };
}
interface setTitle {
    type: EligibilityActionTypes.SET_TITLE;
    payload: {
        title: SelectOption;
    };
}
interface setFirstName {
    type: EligibilityActionTypes.SET_FIRST_NAME;
    payload: {
        firstName: string;
    };
}
interface setLastName {
    type: EligibilityActionTypes.SET_LAST_NAME;
    payload: {
        lastName: string;
    };
}
interface setDob {
    type: EligibilityActionTypes.SET_DOB;
    payload: {
        dob: string;
    };
}
interface setPhone {
    type: EligibilityActionTypes.SET_PHONE;
    payload: {
        phone: string;
    };
}
interface setAddress {
    type: EligibilityActionTypes.SET_ADDRESS;
    payload: Address;
}

interface setResidentialInfo {
    type: EligibilityActionTypes.SET_RESIDENTIAL_INFO;
    payload: {
        residentialStatus: SelectOption;
        timeAtAddress: SelectOption;
    };
}
interface setResidentialValue {
    type: EligibilityActionTypes.SET_RESIDENTIAL_VALUE;
    payload: {
        propertyValue: number;
        outstandingMortgage: number;
    };
}
interface setPreviousAddress {
    type: EligibilityActionTypes.SET_PREVIOUS_ADDRESS;
    payload: Address;
}

interface setIncome {
    type: EligibilityActionTypes.SET_INCOME;
    payload: {
        employmentStatus: SelectOption;
        monthlyIncome: number;
    };
}
interface setEmployment {
    type: EligibilityActionTypes.SET_EMPLOYMENT;
    payload: {
        employerIndustry: SelectOption;
        occupationStartDate: SelectOption;
        companyName: string;
    };
}
interface setExpenditure {
    type: EligibilityActionTypes.SET_EXPENDITURE;
    payload: {
        monthlyMortgageOrRent: number;
        otherExpenses: number;
    };
}
interface setCredit {
    type: EligibilityActionTypes.SET_CREDIT;
    payload: {
        totalOutstandingDebt: number;
        debtExpenses: number;
    };
}
interface setMaritalStatus {
    type: EligibilityActionTypes.SET_MARITAL_STATUS;
    payload: {
        maritalStatus: SelectOption;
        numberOfDependants: number;
    };
}
interface setBankDetails {
    type: EligibilityActionTypes.SET_BANK_DETAILS;
    payload: {
        sortCode: string;
        accountNumber: string;
    };
}
interface setIpAddressUserAgent {
    type: EligibilityActionTypes.SET_IP_ADDRESS_USER_AGENT;
    payload: {
        ipAddress: string;
        userAgent: string
    };
}

interface setExternalRef {
    type: EligibilityActionTypes.SET_EXTERNAL_REF;
    payload: {
        externalRef: string;
    };
}

interface setPaymentFrequency {
    type: EligibilityActionTypes.SET_PAYMENT_FREQUENCY;
    payload: {
        salaryFrequency: SelectOption;
        nextPayDate: string;
    }
}
interface setSource {
    type: EligibilityActionTypes.SET_SOURCE;
    payload: {
        source: string | null;
        adId: string | null;
    }
}

export type EligibilityActions =
    | setId
    | setLoanAmount
    | setRepaymentAmount
    | setEmail
    | setLoanTerm
    | setTitle
    | setFirstName
    | setLastName
    | setDob
    | setPhone
    | setAddress
    | setLoanPurpose
    | setResidentialInfo
    | setResidentialValue
    | setPreviousAddress
    | setIncome
    | setEmployment
    | setExpenditure
    | setCredit
    | setMaritalStatus
    | setIpAddressUserAgent
    | setExternalRef
    | setPaymentFrequency
    | setSource
    | setBankDetails;
