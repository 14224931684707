import {INotifications} from "../../legacy/types/INotifications";
import {NotificationActions, NotificationActionTypes} from "../actions/notificationsActions";

export const defaultState: INotifications = {
    notifications: []
}

export const notificationReducer = (state: INotifications = defaultState, action: NotificationActions) => {
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATIONS:
            return {
                notifications: [...action.payload]
            }
        case NotificationActionTypes.PUSH_NOTIFICATION:
            return {
                notifications: [action.payload, ...state.notifications]
            }
        case NotificationActionTypes.CHECK_NOTIFICATION:
            const checkedMessage = state.notifications.find(n => n.id === action.payload.messageId);
            checkedMessage.isChecked = true;
            const restMessages = state.notifications.filter(n => n.id !== checkedMessage.id);
            return {
                notifications: [...restMessages, checkedMessage]
            }
        default:
            return state
    }
}