import {ICustomer} from "../../legacy/types/user";

export const enum CustomerActionTypes {
    SET_CUSTOMER = 'SET_CUSTOMER',
    DELETE_CUSTOMER = 'DELETE_CUSTOMER'
}

export interface setCustomerAction {
    type: CustomerActionTypes.SET_CUSTOMER;
    payload: ICustomer
}
export interface deleteCustomerAction {
    type: CustomerActionTypes.DELETE_CUSTOMER;
}

export type CustomerActions = setCustomerAction | deleteCustomerAction;