export const enum SettingsActionsTypes {
    SET_SHOULD_REMEMBER = 'SET_SHOULD_REMEMBER',
    SET_PHONE_VERIFIED = 'SET_PHONE_VERIFIED',
    SET_SID = 'SET_SID',
    SET_AUTH = 'SET_AUTH',
    SET_STRIPE_ACTIVE = 'SET_STRIPE_ACTIVE'
}

export interface setShouldRemember {
    type: SettingsActionsTypes.SET_SHOULD_REMEMBER;
    payload: {
        shouldRemember: boolean;
    };
}
export interface setAuth {
    type: SettingsActionsTypes.SET_AUTH;
    payload: {
        jwt: string;
        isAuth: boolean;
    };
}
export interface setSID {
    type: SettingsActionsTypes.SET_SID;
    payload: {
        sid: string;
    };
}
export interface setPhoneVerified {
    type: SettingsActionsTypes.SET_PHONE_VERIFIED;
    payload: {
        isPhoneVerified: boolean;
    };
}

export type SettingsActions =
    | setShouldRemember
    | setAuth
    | setSID
    | setPhoneVerified;
