import {Price, StripeActions, StripeActionTypes} from "../actions/stripeActions";

interface Store {
    prices: Price[],
    clientSecret: string;
    selectedPrice: Price | null
}
const defaultState: Store = {
    prices: [],
    clientSecret: '',
    selectedPrice: null
}
export const stripeReducer = (state = defaultState, action: StripeActions) => {
    switch (action.type) {
        case StripeActionTypes.SET_FETCHED_PRICES: {
            const sortedPrices = action.payload.prices.sort((price1, price2) => {
                if (price1.unit_amount > price2.unit_amount) {
                    return 1
                } else {
                    return -1
                }

            });
            console.log(sortedPrices, 'sortedPrices')
            return {
                ...state,
                prices: [...sortedPrices]
            }
        }
        case StripeActionTypes.SET_PRICE: {
            return {
                ...state,
                selectedPrice: action.payload.price
            }
        }
        case StripeActionTypes.SET_CLIENT_SECRET:
            return {
                ...state,
                clientSecret: action.payload.clientSecret
            }

        default:
            return state
    }
}