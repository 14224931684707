import {
    EligibilityActions,
    EligibilityActionTypes,
} from '../actions/eligibilityActions';
import { SelectOption } from '../../types/select';

export interface Address {
    address1: string;
    address2: string;
    address3?: string;
    building_name: string;
    building_number: string;
    town: string;
    county: string;
    postcode: string;
    street: string;
    fullAddress: string;
}
export interface Eligibility {
    id: string;
    email: string;
    loanAmount: number;
    repaymentAmount: number;
    loanTerm: number;
    loanPurpose: string;
    title: SelectOption;
    firstName: string;
    lastName: string;
    dob: string;
    phone: string;
    address: Address;
    residentialStatus: SelectOption;
    timeAtAddress: SelectOption;
    propertyValue: number;
    outstandingMortgage: number;
    previousAddress: Address;
    employmentStatus: SelectOption;
    monthlyIncome: number;
    employerIndustry: SelectOption;
    occupationStartDate: SelectOption;
    monthlyMortgageOrRent: number;
    utilitiesExpenses: number;
    transportExpenses: number;
    foodExpenses: number;
    totalOutstandingDebt: number;
    debtExpenses: number;
    maritalStatus: SelectOption;
    numberOfDependants: number;
    sortCode: string;
    accountNumber: string;
    ipAddress: string;
    userAgent: string;
    externalRef: string;
    salaryFrequency: SelectOption;
    nextPayDate: string;
    companyName: string;
    source: string | null;
    adId: string | null;
}
const defaultState: Eligibility = {
    id: '',
    email: '',
    loanAmount: 1000,
    repaymentAmount: 0,
    loanTerm: 0,
    loanPurpose: '',
    title: {
        value: 0,
        label: '',
    },
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    address: {
        address1: '',
        address2: '',
        town: '',
        county: '',
        street: '',
        postcode: '',
        building_name: '',
        building_number: '',
        fullAddress: '',
    },
    residentialStatus: {
        value: 0,
        label: '',
    },
    timeAtAddress: {
        value: 0,
        label: '',
    },
    propertyValue: 0,
    outstandingMortgage: 0,
    previousAddress: {
        address1: '',
        address2: '',
        town: '',
        county: '',
        street: '',
        postcode: '',
        building_name: '',
        building_number: '',
        fullAddress: '',
    },
    employmentStatus: {
        value: 0,
        label: '',
    },
    monthlyIncome: 0,
    employerIndustry: {
        value: 0,
        label: '',
    },
    occupationStartDate: {
        value: 0,
        label: '',
    },
    monthlyMortgageOrRent: 0,
    utilitiesExpenses: 0,
    transportExpenses: 0,
    foodExpenses: 0,
    totalOutstandingDebt: 0,
    debtExpenses: 0,
    maritalStatus: {
        value: 0,
        label: '',
    },
    numberOfDependants: 0,
    sortCode: '',
    accountNumber: '',
    ipAddress: '',
    userAgent: '',
    externalRef: '',
    salaryFrequency: {
        label: '',
        value: '',
    },
    nextPayDate: '',
    companyName: '',
    source: '',
    adId: '',
};

export const eligibilityReducer = (
    state: Eligibility = defaultState,
    action: EligibilityActions
): Eligibility => {
    switch (action.type) {
        case EligibilityActionTypes.SET_ID: {
            return {
                ...state,
                id: action.payload.id,
            };
        }
        case EligibilityActionTypes.SET_EMAIL: {
            return {
                ...state,
                email: action.payload.email,
            };
        }
        case EligibilityActionTypes.SET_LOAN_AMOUNT: {
            return {
                ...state,
                loanAmount: action.payload.loanAmount,
            };
        }
        case EligibilityActionTypes.SET_REPAYMENT_AMOUNT: {
            return {
                ...state,
                repaymentAmount: action.payload.repaymentAmount,
            };
        }
        case EligibilityActionTypes.SET_LOAN_TERM: {
            return {
                ...state,
                loanTerm: action.payload.loanTerm,
            };
        }
        case EligibilityActionTypes.SET_LOAN_PURPOSE: {
            return {
                ...state,
                loanPurpose: action.payload.loanPurpose,
            };
        }
        case EligibilityActionTypes.SET_TITLE: {
            return {
                ...state,
                title: action.payload.title,
            };
        }
        case EligibilityActionTypes.SET_FIRST_NAME: {
            return {
                ...state,
                firstName: action.payload.firstName,
            };
        }
        case EligibilityActionTypes.SET_LAST_NAME: {
            return {
                ...state,
                lastName: action.payload.lastName,
            };
        }
        case EligibilityActionTypes.SET_DOB: {
            return {
                ...state,
                dob: action.payload.dob,
            };
        }
        case EligibilityActionTypes.SET_PHONE: {
            return {
                ...state,
                phone: action.payload.phone,
            };
        }
        case EligibilityActionTypes.SET_ADDRESS: {
            const {
                address1,
                address2,
                town,
                fullAddress,
                county,
                postcode,
                street,
                building_name,
                building_number
            } = action.payload;
            return {
                ...state,
                address: {
                    address1,
                    address2,
                    county,
                    town,
                    street,
                    postcode,
                    fullAddress,
                    building_name,
                    building_number,
                },
            };
        }
        case EligibilityActionTypes.SET_RESIDENTIAL_INFO: {
            const { residentialStatus, timeAtAddress } = action.payload;
            return {
                ...state,
                residentialStatus,
                timeAtAddress,
            };
        }
        case EligibilityActionTypes.SET_RESIDENTIAL_VALUE: {
            const { propertyValue, outstandingMortgage } = action.payload;
            return {
                ...state,
                propertyValue,
                outstandingMortgage,
            };
        }
        case EligibilityActionTypes.SET_PREVIOUS_ADDRESS: {
            const {
                address1,
                address2,
                fullAddress,
                county,
                postcode,
                town,
                street,
                building_name,
                building_number,
            } = action.payload;
            return {
                ...state,
                previousAddress: {
                    address1,
                    address2,
                    county,
                    street,
                    town,
                    postcode,
                    fullAddress,
                    building_number,
                    building_name
                },
            };
        }
        case EligibilityActionTypes.SET_INCOME: {
            const { monthlyIncome, employmentStatus } = action.payload;
            return {
                ...state,
                monthlyIncome,
                employmentStatus,
            };
        }
        case EligibilityActionTypes.SET_EMPLOYMENT: {
            const { occupationStartDate, employerIndustry, companyName } =
                action.payload;
            return {
                ...state,
                occupationStartDate,
                employerIndustry,
                companyName,
            };
        }
        case EligibilityActionTypes.SET_EXPENDITURE: {
            const { monthlyMortgageOrRent, otherExpenses } = action.payload;
            return {
                ...state,
                monthlyMortgageOrRent,
                utilitiesExpenses: Math.round(otherExpenses / 3),
                foodExpenses: Math.round(otherExpenses / 3),
                transportExpenses: Math.round(otherExpenses / 3),
            };
        }
        case EligibilityActionTypes.SET_CREDIT: {
            const { totalOutstandingDebt, debtExpenses } = action.payload;
            return {
                ...state,
                totalOutstandingDebt,
                debtExpenses,
            };
        }
        case EligibilityActionTypes.SET_MARITAL_STATUS: {
            const { numberOfDependants, maritalStatus } = action.payload;
            return {
                ...state,
                numberOfDependants,
                maritalStatus,
            };
        }
        case EligibilityActionTypes.SET_BANK_DETAILS: {
            const { sortCode, accountNumber } = action.payload;
            return {
                ...state,
                sortCode,
                accountNumber,
            };
        }
        case EligibilityActionTypes.SET_IP_ADDRESS_USER_AGENT: {
            const { ipAddress, userAgent } = action.payload;
            return {
                ...state,
                ipAddress,
                userAgent
            };
        }
        case EligibilityActionTypes.SET_EXTERNAL_REF: {
            const { externalRef } = action.payload;
            return {
                ...state,
                externalRef,
            };
        }
        case EligibilityActionTypes.SET_PAYMENT_FREQUENCY: {
            const { salaryFrequency, nextPayDate } = action.payload;
            return {
                ...state,
                salaryFrequency,
                nextPayDate,
            };
        }
        case EligibilityActionTypes.SET_SOURCE: {
            const { source, adId } = action.payload;
            return {
                ...state,
                source,
                adId,
            };
        }
        default: {
            return state;
        }
    }
};
