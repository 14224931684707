import {IPaymentDataResponse} from "../../legacy/types/responce";

export const enum PaymentActionsTypes {
    SET_ACTIVE = 'SET_ACTIVE',
    SET_PAYMENTS_DATA = 'SET_PAYMENTS_DATA'
}

export interface setActive {
    type: PaymentActionsTypes.SET_ACTIVE;
    payload: IPaymentDataResponse
}
export interface setPaymentsData {
    type: PaymentActionsTypes.SET_PAYMENTS_DATA;
    payload: IPaymentDataResponse
}

export type PaymentActions = setActive | setPaymentsData
