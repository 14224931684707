import { INotification } from '../../legacy/types/INotifications';

export const enum NotificationActionTypes {
    SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
    PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
    CHECK_NOTIFICATION = 'CHECK_NOTIFICATION',
}

export interface setNotificationsAction {
    type: NotificationActionTypes.SET_NOTIFICATIONS;
    payload: INotification[];
}

export interface pushNotificationsAction {
    type: NotificationActionTypes.PUSH_NOTIFICATION;
    payload: INotification;
}

export interface checkNotificationAction {
    type: NotificationActionTypes.CHECK_NOTIFICATION;
    payload: {
        messageId: string;
    };
}

export type NotificationActions =
    | setNotificationsAction
    | pushNotificationsAction
    | checkNotificationAction;
