import {IPaymentDataResponse} from '../../legacy/types/responce';
import {PaymentActions, PaymentActionsTypes} from "../actions/paymentActions";

const defaultState: IPaymentDataResponse = {
    isActive: false,
    customerId: null,
    subscriptionId: null,
};

export const paymentsReducer = (
    state: IPaymentDataResponse = defaultState,
    action: PaymentActions
): IPaymentDataResponse => {
    switch (action.type) {
        case PaymentActionsTypes.SET_ACTIVE:
            return {
                ...state,
                isActive: true
            }
        case PaymentActionsTypes.SET_PAYMENTS_DATA:
            return {
                ...state,
                customerId: action.payload.customerId,
                subscriptionId: action.payload.subscriptionId,
                isActive: action.payload.isSubscriptionActive
            }
        default:
            return state
    }

};
