import wrapWithProvider from './wrap-with-provider';

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const { pathname } = location;
    if (pathname !== '/') {
        document.body.scrollTo(0, 0)
    }

    return false;
};

// export const onRouteUpdate = ({location, prevLocation}) => {
//     window.scrollTo(0, 0);
// }
export const wrapRootElement = wrapWithProvider;
