import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/redux/store/createStore';
import { CookiesProvider } from 'react-cookie';
import { LoadWrapper } from './src/legacy/components/LoadWrapper';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ChakraProvider } from "@chakra-ui/react";
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore();
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={'6LcpHWEjAAAAAFTExkvT1et7T0jAdon_y6btJFu-'}
            container={{
                element: 'recaptcha-container',
                parameters: { badge: 'inline' },
            }}
        >
            <div id={"recaptcha-container"}></div>
            <CookiesProvider>
                <Provider store={store}>
                    <ChakraProvider>
                        <LoadWrapper>{element}</LoadWrapper>
                    </ChakraProvider>
                </Provider>
            </CookiesProvider>
        </GoogleReCaptchaProvider>
    );
};
