import axios from 'axios';
import { Dispatch } from 'react';
import { navigate } from 'gatsby';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserActions, UserActionTypes } from './actions/userActions';
import {
    SettingsActions,
    SettingsActionsTypes,
} from './actions/settingsActions';
import { UserService } from '../legacy/services/UserService';
import { IUser } from '../legacy/types/user';
import { HealthActions, HealthActionTypes } from './actions/healthActions';
import {
    CustomerActions,
    CustomerActionTypes,
} from './actions/customerActions';
import {
    NotificationActions,
    NotificationActionTypes,
} from './actions/notificationsActions';
import { NotificationService } from '../legacy/services/NotificationService';
import { PaymentActions, PaymentActionsTypes } from './actions/paymentActions';
import { QuizActions, QuizActionTypes } from './actions/quizActions';
import {
    IQuestion,
    QuestionOptionTypes,
} from '../v1/components/Quiz/static/stagesData';
import {
    Price,
    StripeActions,
    StripeActionTypes,
} from './actions/stripeActions';
import {
    EligibilityActions,
    EligibilityActionTypes,
} from './actions/eligibilityActions';
import { Address } from './reducers/eligibilityReducer';
import { SelectOption } from '../types/select';

export const setEmailStage = (email, password, shouldRemember) => {
    return async (dispatch: Dispatch<UserActions | SettingsActions>) => {
        try {
            await dispatch({
                type: UserActionTypes.SET_EMAIL_PASSWORD,
                payload: {
                    email,
                    password,
                },
            });
            await dispatch({
                type: SettingsActionsTypes.SET_SHOULD_REMEMBER,
                payload: {
                    shouldRemember,
                },
            });
            const externalRef = (() => uuidv4())();
            await dispatch({
                type: UserActionTypes.SET_EXTERNAL_REF,
                payload: {
                    externalRef: externalRef,
                },
            });
            await navigate(`/step-1`, {
                state: { prevLink: '/login' },
            });
        } catch (e) {
            console.log(e);
        }
    };
};
export const setFullNameStage = (firstName, middleName, surname) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_FULL_NAME,
            payload: {
                firstName,
                middleName,
                surname,
            },
        });
        await navigate('/step-2');
    };
};
export const setBirthDateStage = (dob) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_AGE,
            payload: {
                dob,
            },
        });
        await navigate('/step-3');
    };
};
export const setPhoneStage = (phone) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_MOBILE_PHONE,
            payload: {
                phone,
            },
        });
        await navigate('/step-4');
    };
};
export const setAddressStage = (
    address1,
    address2,
    address3,
    town,
    county,
    postCode
) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_ADDRESS,
            payload: {
                currentAddress: {
                    address1,
                    address2,
                    address3,
                    town,
                    postCode,
                    county,
                },
            },
        });
        await navigate('/step-5');
    };
};
export const setPreviousAddressStage = (
    address1,
    address2,
    address3,
    town,
    county,
    postCode
) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_PREVIOUS_ADDRESS,
            payload: {
                previousAddress: {
                    address1,
                    address2,
                    address3,
                    town,
                    postCode,
                    county,
                },
            },
        });
        await navigate('/step-8');
    };
};
export const setTimeSpentStage = (overThreeYears) => {
    return async (dispatch: Dispatch<UserActions>) => {
        dispatch({
            type: UserActionTypes.SET_TIME_SPENT,
            payload: {
                overThreeYears,
            },
        });
        if (overThreeYears) {
            await navigate('/step-8');
        } else {
            await navigate('/step-6');
        }
    };
};
export const setDateMovedIn = (dateMovedIn) => {
    return async (dispatch: Dispatch<UserActions>) => {
        await dispatch({
            type: UserActionTypes.SET_DATE_MOVED_IN,
            payload: {
                dateMovedIn: moment(dateMovedIn, 'DD/MM/YYYY').format(),
            },
        });
        if (moment().diff(moment(dateMovedIn, 'DD/MM/YYYY'), 'years') > 3) {
            await navigate('/step-8');
        } else {
            await navigate('/step-6');
        }
    };
};
export const setExternalRef = () => {
    return async (dispatch: Dispatch<UserActions>) => {
        const externalRef = uuidv4();
        dispatch({
            type: UserActionTypes.SET_EXTERNAL_REF,
            payload: {
                externalRef,
            },
        });
    };
};
export const sendVerificationCode = (phone) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        try {
            const response = await axios.post(
                process.env.MESSAGE_SERVICE_URL + '/sendVerificationCode',
                {
                    mobile: phone,
                }
            );
            if (response.data) {
                dispatch({
                    type: SettingsActionsTypes.SET_SID,
                    payload: {
                        sid: response.data.sid,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
};
export const verifyPhone = (verificationCode, sid, phone) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        try {
            const response = await axios.post(
                process.env.MESSAGE_SERVICE_URL + '/verifyCode',
                {
                    mobile: phone,
                    sid,
                    code: verificationCode,
                }
            );
            if (response.data) {
                const { valid, status } = response.data;
                if (status === 'approved') {
                    dispatch({
                        type: SettingsActionsTypes.SET_PHONE_VERIFIED,
                        payload: {
                            isPhoneVerified: true,
                        },
                    });
                    return valid;
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
};
export const signOut = () => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        localStorage.removeItem('jwt');
        dispatch({
            type: SettingsActionsTypes.SET_AUTH,
            payload: {
                isAuth: false,
                jwt: '',
            },
        });
        await navigate('/');
    };
};
export const fetchRememberedUser = () => {
    return async (
        dispatch: Dispatch<
            UserActions | SettingsActions | CustomerActions | PaymentActions
        >
    ) => {
        try {
            const jwt = localStorage.getItem('jwt');
            if (jwt) {
                const user = await UserService.getUser(jwt);
                if (user.data) {
                    dispatch({
                        type: UserActionTypes.SET_FETCHED_USER,
                        payload: {
                            user: user.data.user,
                        },
                    });
                    dispatch({
                        type: SettingsActionsTypes.SET_AUTH,
                        payload: {
                            jwt,
                            isAuth: true,
                        },
                    });
                    dispatch({
                        type: CustomerActionTypes.SET_CUSTOMER,
                        payload: { ...user.data.customer },
                    });
                    dispatch({
                        type: PaymentActionsTypes.SET_PAYMENTS_DATA,
                        payload: user.data.payment,
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
};
export const signIn = (email, password, shouldRemember) => {
    return async (
        dispatch: Dispatch<
            | UserActions
            | SettingsActions
            | CustomerActions
            | PaymentActions
            | HealthActions
        >
    ) => {
        const user = await UserService.signIn(email, password);
        if (user.data) {
            dispatch({
                type: UserActionTypes.SET_FETCHED_USER,
                payload: {
                    user: user.data.user,
                },
            });
            dispatch({
                type: SettingsActionsTypes.SET_AUTH,
                payload: {
                    jwt: user.data.user.jwt,
                    isAuth: true,
                },
            });
            dispatch({
                type: CustomerActionTypes.SET_CUSTOMER,
                payload: { ...user.data.customer },
            });
            dispatch({
                type: PaymentActionsTypes.SET_PAYMENTS_DATA,
                payload: user.data.payment,
            });
            dispatch({
                type: HealthActionTypes.FETCH_USER_HEALTH,
                payload: user.data.health,
            });
            if (shouldRemember) {
                localStorage.setItem('jwt', user.data.user.jwt);
            }
        }
    };
};
export const signUp = (user: IUser) => {
    return async (dispatch: Dispatch<UserActions | SettingsActions>) => {
        try {
            await UserService.signUp(user);
        } catch (e) {
            const err = JSON.parse(e.message);
            console.log(err);
            await navigate('/step-8/failure', {
                state: { errorCode: err.errorCode },
            });
            console.log(e);
        }
    };
};
export const resetPassword = (token, password) => {
    return async (dispatch: Dispatch<UserActions | SettingsActions>) => {
        try {
            const response = await UserService.resetPassword(token, password);
            if (response.data) {
                const { user, jwt } = response.data;
                dispatch({
                    type: UserActionTypes.SET_FETCHED_USER,
                    payload: {
                        user,
                    },
                });
                dispatch({
                    type: SettingsActionsTypes.SET_AUTH,
                    payload: {
                        jwt: token,
                        isAuth: true,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const fetchUserHealth = (userId) => {
    return async (dispatch: Dispatch<HealthActions>) => {
        try {
            const userHealth = await UserService.getAvailableHealthCheck(
                userId
            );
            if (userHealth.data) {
                const { availableTest, score } = userHealth.data;
                dispatch({
                    type: HealthActionTypes.FETCH_USER_HEALTH,
                    payload: {
                        availableTest,
                        score,
                    },
                });
                return score;
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const sendPassedHealthTest = (prevScore, score, testId, jwt) => {
    return async (dispatch: Dispatch<HealthActions>) => {
        try {
            dispatch({
                type: HealthActionTypes.FETCH_USER_HEALTH,
                payload: {
                    availableTest: null,
                    score: prevScore,
                },
            });
            const res = await UserService.sendPassedHealthTest(
                testId,
                score,
                jwt
            );
            if (res.data) {
                const { availableTest, score } = res.data;
                dispatch({
                    type: HealthActionTypes.FETCH_USER_HEALTH,
                    payload: {
                        availableTest,
                        score,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const verifyUser = (
    externalRef: string,
    shouldRemember: boolean,
    userId?: string
) => {
    return async (
        dispatch: Dispatch<
            | CustomerActions
            | UserActions
            | SettingsActions
            | PaymentActions
            | HealthActions
        >
    ) => {
        try {
            const verifiedCustomer = await UserService.verifyUser(
                externalRef,
                userId
            );
            if (verifiedCustomer.data) {
                const customer = verifiedCustomer.data;
                dispatch({
                    type: CustomerActionTypes.SET_CUSTOMER,
                    payload: customer.customer,
                });
                dispatch({
                    type: UserActionTypes.SET_FETCHED_USER,
                    payload: {
                        user: customer.user,
                    },
                });
                dispatch({
                    type: SettingsActionsTypes.SET_AUTH,
                    payload: {
                        jwt: customer.user.jwt,
                        isAuth: true,
                    },
                });
                console.log(customer.payment, 'IN ACTION CREATor');
                dispatch({
                    type: PaymentActionsTypes.SET_PAYMENTS_DATA,
                    payload: customer.payment,
                });
                dispatch({
                    type: HealthActionTypes.FETCH_USER_HEALTH,
                    payload: customer.health,
                });
                if (shouldRemember) {
                    localStorage.setItem('jwt', customer.user.jwt);
                }
            }
        } catch (e) {
            const err = JSON.parse(e.message);
            console.log(err);
            await navigate('/step-8/failure', {
                state: { errorCode: err.errorCode },
            });
            console.log(e);
        }
    };
};

export const getDelayedMessages = (messages) => {
    try {
        return (dispatch: Dispatch<NotificationActions>) => {
            dispatch({
                type: NotificationActionTypes.SET_NOTIFICATIONS,
                payload: messages,
            });
        };
    } catch (e) {
        console.log(e);
    }
};

export const pushMessage = (message) => {
    try {
        return (dispatch: Dispatch<NotificationActions>) => {
            dispatch({
                type: NotificationActionTypes.PUSH_NOTIFICATION,
                payload: message,
            });
        };
    } catch (e) {
        console.log(e);
    }
};

export const checkMessage = (ws, messageId) => {
    try {
        return (dispatch: Dispatch<NotificationActions>) => {
            const notificationService = new NotificationService(ws);
            notificationService.checkMessage(messageId);
            dispatch({
                type: NotificationActionTypes.CHECK_NOTIFICATION,
                payload: {
                    messageId,
                },
            });
        };
    } catch (e) {
        console.log(e);
    }
};

export const deleteUser = (jwt, reason) => {
    try {
        return async (
            dispatch: Dispatch<UserActions | CustomerActions | SettingsActions>
        ) => {
            let userJwt = jwt;
            if (!userJwt) {
                userJwt = localStorage.getItem('jwt');
            }
            await UserService.cancelAccount(userJwt, reason);
            dispatch({
                type: UserActionTypes.DELETE_USER,
            });
            dispatch({
                type: CustomerActionTypes.DELETE_CUSTOMER,
            });
            localStorage.removeItem('jwt');
            await dispatch({
                type: SettingsActionsTypes.SET_AUTH,
                payload: {
                    isAuth: false,
                    jwt: '',
                },
            });
        };
    } catch (e) {
        console.log(e);
    }
};
export const updateEmail = (password, email) => {
    try {
        return async (dispatch: Dispatch<UserActions>) => {
            const jwt = localStorage.getItem('jwt');
            const payload = await UserService.updateEmail(jwt, password, email);
            if (payload.data) {
                dispatch({
                    type: UserActionTypes.SET_EMAIL,
                    payload: {
                        email: payload.data.user.email,
                    },
                });
            }
        };
    } catch (e) {
        console.log(e);
    }
};

export const updatePassword = (password, newPassword) => {
    try {
        return async () => {
            const jwt = localStorage.getItem('jwt');
            await UserService.updatePassword(jwt, password, newPassword);
        };
    } catch (e) {
        console.log(e);
    }
};

export const getStripeSecret = (email) => {
    return async () => {
        try {
            const res = await UserService.fetchStripeClientSecret(email);
        } catch (e) {
            console.log(e);
        }
    };
};

export const setStripeActive = (userId) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        const payload = await UserService.setDefaultPaymentMethod(userId);
        dispatch({
            type: PaymentActionsTypes.SET_ACTIVE,
            payload: {
                isActive: true,
                subscriptionId: 'test',
                customerId: 'test',
            },
        });
        return payload;
    };
};

export const setUpdatedUser = (user, shouldRemember) => {
    return (dispatch: Dispatch<UserActions | SettingsActions>) => {
        dispatch({
            type: UserActionTypes.SET_FETCHED_USER,
            payload: user,
        });
        dispatch({
            type: SettingsActionsTypes.SET_SHOULD_REMEMBER,
            payload: {
                shouldRemember,
            },
        });
        navigate('/broker/ob');
    };
};

export const setUser = (user: IUser) => {
    return (dispatch: Dispatch<UserActions>) => {
        dispatch({
            type: UserActionTypes.SET_FETCHED_USER,
            payload: { user },
        });
    };
};

export const setQuizAnswer = (name: string, answer: QuestionOptionTypes) => {
    return (dispatch: Dispatch<QuizActions>) => {
        dispatch({
            type: QuizActionTypes.ADD_ANSWER,
            payload: { name, answer },
        });
    };
};

export const setQuestions = (questions: IQuestion[]) => {
    return (dispatch: Dispatch<QuizActions>) => {
        dispatch({
            type: QuizActionTypes.SET_QUESTIONS,
            payload: { questions },
        });
    };
};

export const setIsLoading = (value: boolean) => {
    return (dispatch: Dispatch<UserActions>) => {
        dispatch({
            type: UserActionTypes.SET_IS_LOADING,
            payload: { value },
        });
    };
};

export const setPrices = (prices: Price[]) => {
    return (dispatch: Dispatch<StripeActions>) => {
        dispatch({
            type: StripeActionTypes.SET_FETCHED_PRICES,
            payload: {
                prices,
            },
        });
    };
};
export const setPrice = (price: Price) => {
    return (dispatch: Dispatch<StripeActions>) => {
        dispatch({
            type: StripeActionTypes.SET_PRICE,
            payload: {
                price: price,
            },
        });
    };
};
export const fetchUserTC = (userId: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setIsLoading(true));
        try {
            const { data } = await UserService.getUserDataFromBrokerV3(userId);
            const { user, questions, prices } = data;
            dispatch(setUser(user));
            dispatch(setQuestions(questions));
            dispatch(setPrices(prices));
            dispatch(setIsLoading(false));
        } catch (e) {
            dispatch(setIsLoading(false));
            //@TODO show error ?
        }
    };
};

export const setClientSecret = (clientSecret: string) => {
    return (dispatch: Dispatch<StripeActions>) => {
        dispatch({
            type: StripeActionTypes.SET_CLIENT_SECRET,
            payload: {
                clientSecret,
            },
        });
    };
};
export const fetchClientSecret = (userId: string, price: Price) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await UserService.getInvoice(userId, price.id);
            const { invoice } = data;
            dispatch(setClientSecret(invoice));
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateUserV3 = (userId, userData, answers) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(setIsLoading(true));
            const { data } = await UserService.updateUserV3(
                userId,
                userData,
                answers
            );
            console.log(data);
            const { user } = data;
            dispatch({
                type: UserActionTypes.SET_FETCHED_USER,
                payload: { user },
            });
            dispatch(setIsLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const fetchUserDataBSFrank = (userId: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(setIsLoading(true));
            const { data } = await UserService.fetchUserDataFromBroker(userId);
            const { user } = data;
            dispatch(setUser(user));
            dispatch(setIsLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateUser = (user: IUser) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            // dispatch(setIsLoading(true));
            const { data } = await UserService.updateUserData(user.id, user);
            const userPayload = data.user;
            dispatch(setUser(userPayload));
            // dispatch(setIsLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserLoans = (id: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await UserService.getUserLoans(id);
            const userData = data.user;
            const loans = data.loans;
            dispatch(setUser(userData));
            return loans;
        } catch (e) {
            console.log(e);
        }
    };
};
// PIXEL campaign - store
export const setEmail = (email: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_EMAIL,
            payload: {
                email,
            },
        });
    };
};
export const setLoanAmount = (loanAmount: number) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_LOAN_AMOUNT,
            payload: {
                loanAmount,
            },
        });
    };
};

export const setRepaymentAmount = (repaymentAmount: number) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_REPAYMENT_AMOUNT,
            payload: {
                repaymentAmount,
            },
        });
    };
};

export const setLoanTerm = (loanTerm: number) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_LOAN_TERM,
            payload: {
                loanTerm,
            },
        });
    };
};
export const setLoanPurpose = (loanPurpose: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_LOAN_PURPOSE,
            payload: {
                loanPurpose,
            },
        });
    };
};
export const setTitle = (title: SelectOption) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_TITLE,
            payload: {
                title,
            },
        });
    };
};
export const setFirstName = (firstName: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_FIRST_NAME,
            payload: {
                firstName,
            },
        });
    };
};
export const setLastName = (lastName: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_LAST_NAME,
            payload: {
                lastName,
            },
        });
    };
};
export const setDob = (dob: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_DOB,
            payload: {
                dob,
            },
        });
    };
};
export const setPhone = (phone: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_PHONE,
            payload: {
                phone,
            },
        });
    };
};

export const setAddress = ({
    address1,
    address2,
    town,
    county,
    street,
    postcode,
    fullAddress,
}: Address) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_ADDRESS,
            payload: {
                address1,
                address2,
                town,
                county,
                street,
                postcode,
                fullAddress,
            },
        });
    };
};

export const setResidentialInfo = (
    residentialStatus: SelectOption,
    timeAtAddress: SelectOption
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_RESIDENTIAL_INFO,
            payload: {
                residentialStatus,
                timeAtAddress,
            },
        });
    };
};
export const setResidentialValue = (
    propertyValue: number,
    outstandingMortgage: number
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_RESIDENTIAL_VALUE,
            payload: {
                propertyValue,
                outstandingMortgage,
            },
        });
    };
};

export const setPreviousAddress = ({
    address1,
    address2,
    town,
    county,
    street,
    postcode,
    fullAddress,
}: Address) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_PREVIOUS_ADDRESS,
            payload: {
                address1,
                address2,
                town,
                county,
                street,
                postcode,
                fullAddress,
            },
        });
    };
};
export const setIncome = (
    monthlyIncome: number,
    employmentStatus: SelectOption
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_INCOME,
            payload: {
                monthlyIncome,
                employmentStatus,
            },
        });
    };
};
export const setEmployment = (
    employerIndustry: SelectOption,
    occupationStartDate: SelectOption,
    companyName: string
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_EMPLOYMENT,
            payload: {
                occupationStartDate,
                employerIndustry,
                companyName,
            },
        });
    };
};
export const setExpenditure = (
    otherExpenses: number,
    monthlyMortgageOrRent: number
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_EXPENDITURE,
            payload: {
                otherExpenses,
                monthlyMortgageOrRent,
            },
        });
    };
};

export const setCredit = (
    debtExpenses: number,
    totalOutstandingDebt: number
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_CREDIT,
            payload: {
                debtExpenses,
                totalOutstandingDebt,
            },
        });
    };
};
export const setMaritalStatus = (
    maritalStatus: SelectOption,
    numberOfDependants: number
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_MARITAL_STATUS,
            payload: {
                maritalStatus,
                numberOfDependants,
            },
        });
    };
};

export const setBankDetails = (sortCode: string, accountNumber: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_BANK_DETAILS,
            payload: {
                sortCode,
                accountNumber,
            },
        });
    };
};

export const setIpAddress = (ipAddress: string, userAgent: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_IP_ADDRESS_USER_AGENT,
            payload: {
                ipAddress,
                userAgent
            },
        });
    };
};

export const setExternalReference = (externalRef: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_EXTERNAL_REF,
            payload: {
                externalRef,
            },
        });
    };
};

export const setId = (id: string) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_ID,
            payload: {
                id,
            },
        });
    };
};

export const setPaymentFrequency = (
    salaryFrequency: SelectOption,
    nextPayDate: string
) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_PAYMENT_FREQUENCY,
            payload: {
                salaryFrequency,
                nextPayDate,
            },
        });
    };
};

export const setSource = (source: string | null, adId: string | null) => {
    return (dispatch: Dispatch<EligibilityActions>) => {
        dispatch({
            type: EligibilityActionTypes.SET_SOURCE,
            payload: {
                source,
                adId,
            },
        });
    };
};
