import {ISettings} from '../../legacy/types/settings';
import {SettingsActions, SettingsActionsTypes,} from '../actions/settingsActions';

const defaultState: ISettings = {
    shouldRemember: true,
    jwt: '',
    isPhoneVerified: false,
    sid: '',
    isAuth: false,
};

export const settingsReducer = (
    state: ISettings = defaultState,
    action: SettingsActions
): ISettings => {
    switch (action.type) {
        case SettingsActionsTypes.SET_AUTH:
            return {
                ...state,
                jwt: action.payload.jwt,
                isAuth: action.payload.isAuth,
            };
        case SettingsActionsTypes.SET_SHOULD_REMEMBER:
            return {
                ...state,
                shouldRemember: action.payload.shouldRemember
            };
        case SettingsActionsTypes.SET_SID:
            return {
                ...state,
                sid: action.payload.sid
            }
        case SettingsActionsTypes.SET_PHONE_VERIFIED:
            return {
                ...state,
                isPhoneVerified: action.payload.isPhoneVerified
            }
        default:
            return state;
    }
};