import { IUser } from '../../legacy/types/user';

export const enum UserActionTypes {
  SET_EMAIL_PASSWORD = 'SET_EMAIL_PASSWORD',
  SET_FULL_NAME = 'SET_FULL_NAME',
  SET_AGE = 'SET_AGE',
  SET_MOBILE_PHONE = 'SET_MOBILE_PHONE',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_TIME_SPENT = 'SET_TIME_SPENT',
  SET_PREVIOUS_ADDRESS = 'SET_PREVIOUS_ADDRESS',
  SET_VALIDATION_SID = 'SET_VALIDATION_SID',
  SET_PHONE_VERIFIED = 'SET_PHONE_VERIFIED',
  SET_USER_AUTH = 'SET_USER_AUTH',
  SET_FETCHED_USER = 'SET_FETCHED_USER',
  SET_USER_SIGN_OUT = 'SET_USER_SIGN_OUT',
  SET_EXTERNAL_REF = 'SET_EXTERNAL_REF',
  SET_VERIFIED = 'SET_VERIFIED',
  DELETE_USER = 'DELETE_USER',
  SET_EMAIL = 'SET_EMAIL',
  SET_DATE_MOVED_IN = 'SET_DATE_MOVED_IN',
  SET_IS_LOADING = 'SET_IS_LOADING',
}

export interface setEmailPasswordAction {
  type: UserActionTypes.SET_EMAIL_PASSWORD;
  payload: {
    email: string;
    password: string;
  };
}

interface setFullNameAction {
  type: UserActionTypes.SET_FULL_NAME;
  payload: {
    firstName: string;
    middleName: string;
    surname: string;
  };
}

interface setAge {
  type: UserActionTypes.SET_AGE;
  payload: {
    dob: string;
  };
}

interface setMobilePhone {
  type: UserActionTypes.SET_MOBILE_PHONE;
  payload: {
    phone: string;
  };
}

interface setAddress {
  type: UserActionTypes.SET_ADDRESS;
  payload: {
    currentAddress: {
      address1: string;
      address2: string;
      address3: string;
      town: string;
      county: string;
      postCode: string;
    };
  };
}

interface setDateMovedIn {
  type: UserActionTypes.SET_DATE_MOVED_IN;
  payload: {
    dateMovedIn: string
  };

}

interface setTimeSpent {
  type: UserActionTypes.SET_TIME_SPENT;
  payload: {
    overThreeYears: boolean;
  };
}

interface setPreviousAddress {
  type: UserActionTypes.SET_PREVIOUS_ADDRESS;
  payload: {
    previousAddress: {
      address1: string;
      address2: string;
      address3: string;
      town: string;
      county: string;
      postCode: string;
    };
  };
}

export interface setUserAuth {
  type: UserActionTypes.SET_USER_AUTH;
  payload: {
    isAuth: boolean;
    jwt: string;
  };
}

export interface setFetchedUser {
  type: UserActionTypes.SET_FETCHED_USER;
  payload: {
    user: IUser;
  };
}

interface setExternalRef {
  type: UserActionTypes.SET_EXTERNAL_REF;
  payload: {
    externalRef: string;
  };
}

interface deleteUser {
  type: UserActionTypes.DELETE_USER;
}

interface updateEmail {
  type: UserActionTypes.SET_EMAIL;
  payload: { email: string };
}

interface setIsLoading {
  type: UserActionTypes.SET_IS_LOADING;
  payload: { value: boolean };
}

export type UserActions =
    | setEmailPasswordAction
    | setFullNameAction
    | setAge
    | setMobilePhone
    | setAddress
    | setTimeSpent
    | setPreviousAddress
    | setUserAuth
    | setFetchedUser
    | setExternalRef
    | deleteUser
    | updateEmail
    | setDateMovedIn
    | setIsLoading
