import { IQuestion, QuestionOptionTypes } from '../../v1/components/Quiz/static/stagesData';

export const enum QuizActionTypes {
  ADD_ANSWER = 'ADD_ANSWER',
  SET_QUESTIONS = 'SET_QUESTIONS',
}

export interface addAnswerAction {
  type: QuizActionTypes.ADD_ANSWER;
  payload: {
    name: string
    answer: QuestionOptionTypes
  };
}

export interface setQuestionsAction {
  type: QuizActionTypes.SET_QUESTIONS;
  payload: {
    questions: IQuestion[]
  };
}

export type QuizActions = addAnswerAction | setQuestionsAction